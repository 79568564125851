import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import {  fa42Group, faAccusoft, faAdn, faBluetoothB, faHouzz, faItchIo, faShopware, faTeamspeak, faWhatsapp} from "@fortawesome/free-brands-svg-icons";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);


  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <nav className={
      "navbar fixed top-0 h-16 w-full z-50" +
      (props.transparent ? " bg-white" : " bg-white shadow-lg") +
      " flex flex-wrap items-center justify-between px-2 py-1"
    }>
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative shadow-lg flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          {window.innerWidth < 540 && (
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={toggleNavbar}
            >
              <i className={(props.transparent ? "text-white" : "text-gray-800") + " fas fa-bars"}></i>
            </button>
          )}
          <Link
            className={
              (props.transparent ? "text-slate-600" : "text-gray-800") +
              " text-4xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            }
            to="/"
          >
            DIMAC IT
          </Link>
        </div>
        <div className={
          "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
          (navbarOpen ? " block rounded shadow-lg" : " hidden lg:block")
        }>
          <div className="flex flex-col list-none mr-auto lg:flex-row">
            <div
              className={
                (props.transparent ? "lg:text-black lg:hover:text-gray-300 text-gray-800" : "text-gray-800 hover:text-gray-600 text-2xl") +
                " px-8 py-4 lg:py-6 flex items-center text-base uppercase font-bold"
              }
            >
              <Link to="/"><FontAwesomeIcon icon={faItchIo} /> Home</Link>
            </div>
            <div
              className={
                (props.transparent ? "lg:text-black lg:hover:text-gray-300 text-gray-800" : "text-gray-800 hover:text-gray-600") +
                " px-8 py-4 lg:py-6 flex items-center text-base uppercase font-bold"
              }
            >
              <Link to="/LandingServices"><FontAwesomeIcon icon={faAccusoft} /> POS SYSTEM</Link>
            </div>
            <div
              className={
                (props.transparent ? "lg:text-black lg:hover:text-gray-300 text-gray-800" : "text-gray-800 hover:text-gray-600") +
                " px-8 py-4 lg:py-6 flex items-center text-base uppercase font-bold"
              }
            >
              <Link to="/SecurityandVideo"><FontAwesomeIcon icon={faBluetoothB} />  SECURITY SYSTEM, AUDIO & HOME AUTOMATION</Link>
            </div>
            <div
              className={
                (props.transparent ? "lg:text-black lg:hover:text-gray-300 text-gray-800 justify-end" : "text-gray-800 hover:text-gray-600") +
                " px-8 py-4 lg:py-6 flex items-center text-base uppercase font-bold justify-end"
              }
            >
              <Link to="/Copilot"><FontAwesomeIcon icon={faTeamspeak} />  SUPPORT ON LINE</Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}




