import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}

export const SecurityandVideo = () => {
  return (
    <>
      <Navbar transparent />
      <main>
        <div
          className="relative  pt-15 pb-12 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: "url('../demo/images/screen-4.png')",
            }}
          >
            <span
              id="blackOverlay"
              className="bg-cyan-900 w-full h-full absolute opacity-20 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-black font-bold text-5xl">Welcome</h1>
                  <p className="mt-1 text-2xl text-white-300 font-bold">
                    your business ally we are a technology services company.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden bg-cyan-900"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>
        <section className="pb-20">
          <Card className="w-full max-w-[78rem] flex-row">
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 w-3/5 shrink-0 rounded-r-none"
            >
              <img
                src="https://img.freepik.com/premium-photo/concept-modern-smart-home-generated-ai_741672-1330.jpg"
                alt="card-image"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
              <Typography variant="h3" color="gray" className="mb-4 uppercase">
                Security System, AUDIO & HOME AUTOMATION
              </Typography>
              <ul>
                <li>
                  <Typography>
                    At DIMAC IT, we offer comprehensive audio and video
                    solutions to meet our clients' needs for quality,
                    technology, and security. We work with leading brands in the
                    market, such as Hochiki, Honeywell, and Hikvision, to
                    provide high-quality and high-performance products.
                  </Typography>
                  <br />
                </li>
                <li>
                  <Typography variant="h4">Our services include:</Typography>
                  <br />
                </li>
                <li className="flex items-center gap-3">
                  <span className="rounded-full border border-cyan-200 bg-cyan-600 p-1">
                    <CheckIcon />
                  </span>
                  <Typography variant="h8" color="blue-gray" className="mb-3">
                    <span className="font-bold">
                      Installation and Configuration of Audio and Video Systems:
                    </span>
                    {"  "}
                    We design and implement custom audio and video systems for
                    homes, businesses, and commercial spaces, ensuring an
                    exceptional audio and video experience.
                  </Typography>
                </li>
                <li className="flex items-center gap-3">
                  <span className="rounded-full border border-cyan-200 bg-cyan-600 p-1">
                    <CheckIcon />
                  </span>
                  <Typography variant="h8" color="blue-gray" className="mb-3">
                    <span className="font-bold">
                      Integration of Security Systems:{" "}
                    </span>
                    We integrate advanced security systems with high-definition
                    (HD) surveillance cameras and IP cameras from Hikvision,
                    ensuring the continuous protection and surveillance of your
                    property.
                  </Typography>
                </li>
                <li className="flex items-center gap-3">
                  <span className="rounded-full border border-cyan-200 bg-cyan-600 p-1">
                    <CheckIcon />
                  </span>
                  <Typography variant="h8" color="blue-gray" className="mb-3">
                    <span className="font-bold">
                      Background Music Solutions:{" "}
                    </span>{" "}
                    We create pleasant acoustic environments with high-fidelity
                    background music systems, ideal for restaurants, hotels,
                    shops, and other commercial spaces.
                  </Typography>
                </li>
                <li className="flex items-center gap-3">
                  <span className="rounded-full border border-cyan-200 bg-cyan-600 p-1">
                    <CheckIcon />
                  </span>
                  <Typography variant="h8" color="blue-gray" className="mb-3">
                    <span className="font-bold">
                      Conference Room Solutions:{" "}
                    </span>{" "}
                    We implement audio and video systems for conference rooms,
                    ensuring clear and effective communication at all times.
                  </Typography>
                </li>
                <li className="flex items-center gap-3">
                  <span className="rounded-full border border-cyan-200 bg-cyan-600 p-1">
                    <CheckIcon />
                  </span>
                  <Typography variant="h8" color="blue-gray" className="mb-3">
                    <span className="font-bold">
                      Technical Support and Maintenance:
                    </span>{" "}
                    We provide technical support and maintenance services to
                    ensure the optimal operation of your audio and video
                    systems.
                  </Typography>
                </li>
                <li className="flex items-center gap-3">
                  <span className="rounded-full border border-cyan-200 bg-cyan-600 p-1">
                    <CheckIcon />
                  </span>
                  <Typography variant="h8" color="blue-gray" className="mb-3">
                    <span className="font-bold">Home Automation:</span>{" "} We
                    integrate audio and video systems with home automation
                    technology, allowing you to control your devices from
                    anywhere, at any time.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    At DIMAC IT, we are committed to offering innovative and
                    high-quality audio and video solutions, backed by
                    world-renowned brands such as Hochiki, Honeywell, and
                    Hikvision. Contact us today to discover how we can enhance
                    your audiovisual experience!
                  </Typography>
                </li>
              </ul>
            </CardBody>
          </Card>
        </section>
        <section
          className="h-screen bg-cover bg-center bg-no-repeat relative block py-1  lg:pt-0"
          style={{
            backgroundImage: "url('../demo/images/screen-6.png')",
            height: "70vh",
          }}
        ></section>
      </main>
      <Footer />
    </>
  );
};
