import React from "react";
import { CopilotWidget, Root } from "@openchatai/copilot-widget";
/* import '@openchatai/copilot-widget/index.css'  */
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";

const options = {
  apiUrl: "https://api.opencopilot.so/backend",
  socketUrl: "https://api.opencopilot.so",
  initialMessage:
    "Hey! how are you, tell me your name, it would be very nice to help you today",
  token: "cm2UNfSYhaQEU9nH", // you can get your token from the dashboard
  defaultOpen: true,
  headers: {
    // optional: you can pass your authentication tokens to the copilot or any other header you want to send with every request
    Authorization:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcxMTU1MjgzOSwianRpIjoiOGIzODZkMDktNGE2NS00NTQwLWIyOWEtZmFlNTQ5NThmODQ0IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6eyJpZCI6OTAwMjM1NywibmFtZSI6Ikp1YW4gUmlvcyIsImVtYWlsIjoiZ3VpZ3Vpcmlvc2JpdEBnbWFpbC5jb20ifSwibmJmIjoxNzExNTUyODM5LCJjc3JmIjoiMWViYjkwNjYtNzEyMC00ZWZkLTk1ODctOWExYjA3YzE1ODMwIiwiZXhwIjoxNzEyMTU3NjM5fQ.AT306bSRqf8ociGUkzGfWYspkxi8nYpzLW6bKNPmQHg",
    AnyKey: "AnyValue",
  },
};
const containerProps = {
  // optional: you can pass any props to the container div
  className: "your-custom-class-name",
  style: {
    position: "fixed",
    height: "90%",
    bottom: "5%",
    right: "0",
    width: "680px",
    opacity: "0.75",
  },
};

function Copilot() {
  return (
    <>
      <div>
        <Navbar transparent />

        <Helmet>
          <style>{`
          body {
            background-image: url('../demo/images/support.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-attachment: fixed;
          }
        `}</style>
        </Helmet>
      </div>
      <div>
        <Root containerProps={containerProps} options={options}>
          <CopilotWidget triggerSelector="#copilot-trigger" />
        </Root>
      </div>
    </>
  );
}

export default Copilot;

/* function Widget(){
  return (
    
  )
}
 */
