/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}

export const LandingServices = () => {
  return (
    <>
      <Navbar transparent />
      <main>
        <div
          className="relative  pt-15 pb-12 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: "url('../demo/images/screen-4.png')",
            }}
          >
            <span
              id="blackOverlay"
              className="bg-cyan-900 w-full h-full absolute opacity-20 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-black font-bold text-5xl">Welcome</h1>
                  <p className="mt-1 text-2xl text-white-300 font-bold">
                    your business ally we are a technology services company.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden bg-cyan-900"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>
        <section className="pb-20">
          <Card className="w-full max-w-[78rem] flex-row">
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 w-3/5 shrink-0 rounded-r-none"
            >
              <img
                src="https://img.freepik.com/free-photo/waitress-preparing-cake_23-2147821324.jpg"
                alt="card-image"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
              <Typography variant="h3" color="gray" className="mb-4 uppercase">
                Pos System
              </Typography>
              <ul>
                <li>
                  <Typography>
                    Welcome to Dimac IT, your destination for industry-leading
                    point-of-sale solutions.
                    <br />
                    At DIMAC IT, we understand the importance of having a
                    reliable and efficient POS system for your business. That's
                    why we're dedicated to offering support and sales services
                    for high-quality POS systems, including renowned brands like
                    Aldelo, Future POS, and Clover.
                  </Typography>
                  <br />
                </li>
                <li>
                  <Typography variant="h4">Our services include:</Typography>
                  <br />
                </li>
                <li className="flex items-center gap-3">
                  <span className="rounded-full border border-cyan-200 bg-cyan-600 p-1">
                    <CheckIcon />
                  </span>
                  <Typography variant="h8" color="blue-gray" className="mb-3">
                    <span className="font-bold">POS System Sales:</span> We
                    offer a wide range of state-of-the-art POS systems to meet
                    the needs of your business, from small businesses to large
                    restaurant chains. Our systems are designed to be
                    user-friendly, reliable, and scalable to accommodate your
                    business growth.
                  </Typography>
                </li>
                <li className="flex items-center gap-3">
                  <span className="rounded-full border border-cyan-200 bg-cyan-600 p-1">
                    <CheckIcon />
                  </span>
                  <Typography variant="h8" color="blue-gray" className="mb-3">
                    <span className="font-bold">Technical Support:</span> Our
                    team of POS system experts is available to provide you with
                    the technical support you need to keep your system running
                    smoothly. Whether you need help with installation,
                    configuration, or troubleshooting, we're here to assist you.
                  </Typography>
                </li>
                <li className="flex items-center gap-3">
                  <span className="rounded-full border border-cyan-200 bg-cyan-600 p-1">
                    <CheckIcon />
                  </span>
                  <Typography variant="h8" color="blue-gray" className="mb-3">
                    <span className="font-bold">
                      Upgrades and Enhancements:
                    </span>{" "}
                    We keep our POS systems up to date with the latest features
                    and improvements to ensure that your business stays at the
                    forefront of technology. We make sure that your system is
                    always optimized to maximize efficiency and profitability.
                  </Typography>
                </li>
                <li className="flex items-center gap-3">
                  <span className="rounded-full border border-cyan-200 bg-cyan-600 p-1">
                    <CheckIcon />
                  </span>
                  <Typography variant="h8" color="blue-gray" className="mb-3">
                    <span className="font-bold">Payment Integration:</span> We
                    make it easy to integrate secure and reliable payment
                    systems into your POS system, so you can accept payments
                    quickly and securely, whether in person or online.
                  </Typography>
                </li>
                <li className="flex items-center gap-3">
                  <span className="rounded-full border border-cyan-200 bg-cyan-600 p-1">
                    <CheckIcon />
                  </span>
                  <Typography variant="h8" color="blue-gray" className="mb-3">
                    <span className="font-bold">Custom Consulting:</span> Our
                    POS system experts are available to provide you with
                    personalized consulting to help you find the perfect
                    solution for your business's specific needs. We ensure that
                    you get the maximum value from your investment in POS
                    systems.
                  </Typography>
                </li>
              </ul>
            </CardBody>
          </Card>
        </section>
        <section
          className="h-screen bg-cover bg-center bg-no-repeat relative block py-20  lg:pt-0"
          style={{
            backgroundImage: "url('../demo/images/screen-5.png')",
            height: "60vh",
          }}
        ></section>
      </main>
      <Footer />
    </>
  );
};
