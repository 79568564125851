import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Root, CopilotWidget } from '@openchatai/copilot-widget';
import { LandingServices } from "./components/LandingServices";
import Landing from "./components/Landing";
import { SecurityandVideo } from "./components/SecurityandVideo";
import Login from "./components/Login";
import { ButtonFloat } from './components/ButtonFloat';
import Copilot from "./components/Copilot";



function App() {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    document.title = "DIMAC IT";
  }, []);

  const toggleWidget = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Router>
      
      <Routes>
        <Route path="/LandingServices" element={<LandingServices />} />
        <Route path="/SecurityandVideo" element={<SecurityandVideo />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/" element={<Landing />} />
        <Route path="/ButtonFloat" element={<ButtonFloat />} />
        <Route path="/Copilot" element={<Copilot />} />
      </Routes>
      
    </Router>
  );
}

export default App;
